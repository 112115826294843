import React, { useState, useEffect } from 'react';
import styles from './AcctDetailsPage.module.css';
import AcctHeaderBar from './AcctHeaderBar';
import FooterBar from '../common/FooterBar';
import { devClient } from '../api/client';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPen, faCheck, faTimes } from '@fortawesome/free-solid-svg-icons';

const AccountDetailsPage = () => {
  const [isEditing, setIsEditing] = useState(false);
  const [accountData, setAccountData] = useState({
    name: '',
    email: '',
    username: '',
    organization: '',
    profilePicture: null,
    bio: '',
    country: '',
    websiteUrl: '',
  });
  const [originalData, setOriginalData] = useState({});

  useEffect(() => {
    fetchAccountDetails();
  }, []);

  const fetchAccountDetails = async () => {
    try {
      const accessToken = await localStorage.getItem('accessToken');
      const response = await devClient.get('/v1/developer/account/', {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
      setAccountData(response.data);
      setOriginalData(response.data);
    } catch (error) {
      console.error('Error fetching account details:', error);
    }
  };

  const handleChange = (e) => {
    const { name, value, type, files } = e.target;
    setAccountData(prev => ({
      ...prev,
      [name]: type === 'file' ? files[0] : value
    }));
  };

  const handleUpdate = async () => {
    try {
      const formData = new FormData();
      for (const key in accountData) {
        if (accountData[key] !== originalData[key]) {
          formData.append(key, accountData[key]);
        }
      }
      const accessToken = await localStorage.getItem('accessToken');
      const response = await devClient.patch('/v1/developer/account/update/', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${accessToken}`,
        }
      });
      if (response.status === 200) {
        setIsEditing(false);
        setOriginalData(accountData);
        alert('Account updated successfully!');
      }
    } catch (error) {
      console.error('Error updating account:', error);
      alert('Failed to update account');
    }
  };

  const cancelEdit = () => {
    setAccountData(originalData);
    setIsEditing(false);
  };

  const menuItems = [
    { label: 'Dashboard', href: '/35lz6vm87q425vd9-portfolio' },
    { label: 'Documentation', href: '#docs' },
  ];

  return (
    <div className={styles.accountDetailsPage}>
      <AcctHeaderBar menuItems={menuItems} isSignedIn={true} />
      <main className={styles.mainContent}>
        <div className={styles.header}>
          <h1>Account Details</h1>
          {!isEditing ? (
            <button 
              className={styles.editButton}
              onClick={() => setIsEditing(true)}
            >
              <FontAwesomeIcon icon={faPen} /> Edit
            </button>
          ) : (
            <div className={styles.editActions}>
              <button 
                className={styles.saveButton}
                onClick={handleUpdate}
              >
                <FontAwesomeIcon icon={faCheck} /> Save
              </button>
              <button 
                className={styles.cancelButton}
                onClick={cancelEdit}
              >
                <FontAwesomeIcon icon={faTimes} /> Cancel
              </button>
            </div>
          )}
        </div>

        <div className={styles.detailsContainer}>
          <div className={styles.profileSection}>
            <div className={styles.profilePicture}>
              {accountData.profilePicture ? (
                <img src={accountData.profilePicture} alt="Profile" />
              ) : (
                <div className={styles.placeholderImage}>
                  {accountData.name?.charAt(0) || 'U'}
                </div>
              )}
              {isEditing && (
                <input
                  type="file"
                  name="profilePicture"
                  onChange={handleChange}
                  className={styles.fileInput}
                />
              )}
            </div>
          </div>

          <div className={styles.detailsSection}>
            <div className={styles.fieldGroup}>
              <label>Name</label>
              {isEditing ? (
                <input
                  type="text"
                  name="name"
                  value={accountData.name}
                  onChange={handleChange}
                />
              ) : (
                <p>{accountData.name}</p>
              )}
            </div>

            <div className={styles.fieldGroup}>
              <label>Email</label>
              {isEditing ? (
                <input
                  type="email"
                  name="email"
                  value={accountData.email}
                  onChange={handleChange}
                />
              ) : (
                <p>{accountData.email}</p>
              )}
            </div>

            <div className={styles.fieldGroup}>
              <label>Organization</label>
              {isEditing ? (
                <input
                  type="text"
                  name="organization"
                  value={accountData.organization}
                  onChange={handleChange}
                />
              ) : (
                <p>{accountData.organization || 'Not specified'}</p>
              )}
            </div>

            <div className={styles.fieldGroup}>
              <label>Bio</label>
              {isEditing ? (
                <textarea
                  name="bio"
                  value={accountData.bio}
                  onChange={handleChange}
                  rows="4"
                />
              ) : (
                <p>{accountData.bio || 'No bio provided'}</p>
              )}
            </div>

            <div className={styles.fieldGroup}>
              <label>Country/Region</label>
              {isEditing ? (
                <input
                  type="text"
                  name="country"
                  value={accountData.country}
                  onChange={handleChange}
                />
              ) : (
                <p>{accountData.country}</p>
              )}
            </div>

            <div className={styles.fieldGroup}>
              <label>Website</label>
              {isEditing ? (
                <input
                  type="url"
                  name="websiteUrl"
                  value={accountData.websiteUrl}
                  onChange={handleChange}
                />
              ) : (
                <p>
                  {accountData.websiteUrl ? (
                    <a href={accountData.websiteUrl} target="_blank" rel="noopener noreferrer">
                      {accountData.websiteUrl}
                    </a>
                  ) : (
                    'Not specified'
                  )}
                </p>
              )}
            </div>
          </div>
        </div>
      </main>
      <FooterBar />
    </div>
  );
};

export default AccountDetailsPage; 
import React from 'react';
import styles from './AcctHeaderBar.module.css'; // Import the CSS module
import logo from '../../assets/logo.jpeg';
import { useAuth } from '../context/AuthContext';
import { useNavigate } from 'react-router-dom';

const HeaderBar = ({ menuItems, isSignedIn }) => {
  const { logout } = useAuth();
  const navigate = useNavigate();

  const handleLogout = async (e) => {
    e.preventDefault();
    const confirmLogout = window.confirm(`Are you sure you want to logout?`);
    if(confirmLogout) {
      await logout();
      navigate('/login');
    }
  };

  return (
    <header>
      <div className={styles.headerContent}>
        <div className={styles.headerLeft}>
          <a href="/" className={styles.brandLink}>
            <img src={logo} alt="Logo" className={styles.logo} />
            <div className={styles.title}>MAZE</div>
          </a>
        </div>
        <div className={styles.headerRight}>
          <nav>
            {menuItems.map((item, index) => (
              <a key={index} href={item.href} >{item.label}</a>
            ))}
          </nav>
          {isSignedIn ? (
            <button className={styles.signOutBtn} onClick={handleLogout}>Sign Out</button>
          ) : (
            <button className={styles.signInBtn} onClick={() => navigate('/login')}>Sign In</button>
          )}
        </div>
      </div>
    </header>
  );
};

export default HeaderBar;

import React, { useState, useRef, useEffect } from 'react';
import styles from './TermsModal.module.css';

const TermsModal = ({ isOpen, onClose }) => {
  const [showCloseButton, setShowCloseButton] = useState(false);
  const contentRef = useRef(null);

  const handleScroll = () => {
    if (contentRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = contentRef.current;
      const isAtBottom = scrollTop + clientHeight >= scrollHeight - 20;
      setShowCloseButton(isAtBottom);
    }
  };

  useEffect(() => {
    if (isOpen && contentRef.current) {
      contentRef.current.scrollTop = 0;
      setShowCloseButton(false);
    }
  }, [isOpen]);

  if (!isOpen) return null;

  return (
    <div className={styles.modalOverlay}>
      <div className={styles.modal}>
        <div className={styles.modalHeader}>
          <h2>Terms and Conditions</h2>
          <button 
            className={styles.closeIcon}
            onClick={onClose}
            aria-label="Close"
          >
            ×
          </button>
        </div>

        <div 
          ref={contentRef}
          className={styles.modalContent}
          onScroll={handleScroll}
        >
          <h3>1. Introduction</h3>
          <p>Welcome to our platform. By accessing or using our service, you agree to be bound by these Terms and Conditions. Please read these terms carefully before proceeding.</p>

          <h3>2. Acceptance of Terms</h3>
          <p>By creating an account or using any part of our service, you acknowledge that you have read, understood, and agree to be bound by these terms. If you do not agree to these terms, please do not use our service.</p>

          <h3>3. User Eligibility</h3>
          <p>You must be at least 18 years old to use this service. By using this service, you represent and warrant that you have the right, authority, and capacity to enter into these terms and to abide by all of the terms and conditions set forth herein.</p>

          <h3>4. User Account</h3>
          <p>To access certain features of our service, you must create an account. You are responsible for:</p>
          <ul>
            <li>Maintaining the confidentiality of your account credentials</li>
            <li>All activities that occur under your account</li>
            <li>Notifying us immediately of any unauthorized use</li>
          </ul>

          <h3>5. Intellectual Property Rights</h3>
          <p>All content, features, and functionality of our service, including but not limited to text, graphics, logos, and software, are owned by us or our licensors and are protected by intellectual property laws.</p>

          <h3>6. User Applications and Assets</h3>
          <p>When you upload applications and assets to our service:</p>
          <ul>
            <li><strong>Ownership:</strong> You retain all ownership rights to your applications and assets uploaded to our platform</li>
            <li><strong>License Grant:</strong> By uploading, you grant us a worldwide, non-exclusive, royalty-free license to host, store, transfer, display, and distribute your applications and assets for the purpose of providing our services</li>
            <li><strong>Acceptable Use:</strong> Your uploaded content must:
              <ul>
                <li>Not violate any intellectual property rights</li>
                <li>Comply with all applicable laws and regulations</li>
                <li>Not contain malicious code or harmful components</li>
                <li>Follow our platform's technical specifications and guidelines</li>
              </ul>
            </li>
            <li><strong>Storage and Distribution:</strong> We will:
              <ul>
                <li>Maintain reasonable security measures to protect your uploads</li>
                <li>Serve your applications and assets through our CDN infrastructure</li>
                <li>Provide analytics and usage statistics for your uploads</li>
                <li>Enable you to manage and update your content</li>
              </ul>
            </li>
            <li><strong>Removal Rights:</strong> We reserve the right to remove any content that violates our terms or poses security risks</li>
          </ul>

          <h3>7. Service Level Commitment</h3>
          <p>For hosting and serving your applications and assets:</p>
          <ul>
            <li>We strive to maintain 99.9% uptime for our hosting services</li>
            <li>We provide secure HTTPS delivery for all content</li>
            <li>We maintain regular backups of your uploaded content</li>
            <li>We offer technical support for hosting-related issues</li>
          </ul>

          <h3>8. User Conduct</h3>
          <p>You agree not to:</p>
          <ul>
            <li>Use the service for any illegal purpose</li>
            <li>Violate any applicable laws or regulations</li>
            <li>Impersonate any person or entity</li>
            <li>Interfere with or disrupt the service</li>
          </ul>

          <h3>9. Privacy Policy</h3>
          <p>Your privacy is important to us. Our Privacy Policy explains how we collect, use, and protect your personal information. By using our service, you consent to our Privacy Policy.</p>

          <h3>10. Limitation of Liability</h3>
          <p>To the maximum extent permitted by law, we shall not be liable for any indirect, incidental, special, consequential, or punitive damages, or any loss of profits or revenues.</p>

          <h3>11. Account Termination</h3>
          <p>We reserve the right to terminate or suspend your account and access to our service at our sole discretion, without notice, for conduct that we believe violates these terms or is harmful to other users, us, or third parties.</p>

          <h3>12. Changes to Terms</h3>
          <p>We reserve the right to modify these terms at any time. We will notify users of any material changes via email or through our service. Your continued use of the service after such modifications constitutes acceptance of the updated terms.</p>

          <h3>13. Governing Law</h3>
          <p>These terms shall be governed by and construed in accordance with the laws of India. Any disputes arising under or in connection with these terms shall be subject to the exclusive jurisdiction of the courts in Bangalore, Karnataka, India.</p>

          <h3>14. Dispute Resolution</h3>
          <p>Any dispute, controversy, or claim arising out of or relating to these terms, or the breach, termination, or invalidity thereof, shall first attempt to be settled through good-faith negotiations. If such negotiations fail, the dispute shall be finally resolved by the competent courts in Bangalore, Karnataka, India.</p>

          <h3>15. Contact Information</h3>
          <p>If you have any questions about these Terms and Conditions, please contact us at support@maze.social.</p>

          <p><strong>Last Updated:</strong> November 18, 2024</p>
        </div>

        <div className={styles.modalFooter}>
          {showCloseButton && (
            <button 
              className={styles.acceptButton}
              onClick={onClose}
            >
              Close
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default TermsModal; 
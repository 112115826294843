import React, { useState, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUpload, faTimes, faImage } from '@fortawesome/free-solid-svg-icons';
import styles from './AcctAppDetailsComponent.module.css';
import { devClient } from '../api/client';
import { useNavigate } from 'react-router-dom';

const CategorySelect = ({ selectedCategories, onCategoryChange, customCategory, onCustomCategoryChange, disabled }) => {
  const categories = [
    {
      value: 'action',
      title: 'Action',
      description: 'Fast-paced games involving combat, adventure, or challenges requiring quick reflexes'
    },
    {
      value: 'adventure',
      title: 'Adventure',
      description: 'Story-driven games with exploration, puzzles, and immersive worlds'
    },
    {
      value: 'arcade',
      title: 'Arcade',
      description: 'Classic, simple, and addictive gameplay, often with short sessions'
    },
    {
      value: 'puzzle',
      title: 'Puzzle',
      description: 'Brain-teasing games requiring logic, problem-solving, or strategy'
    },
    {
      value: 'strategy',
      title: 'Strategy',
      description: 'Games that involve planning, resource management, or tactical decisions'
    },
    {
      value: 'simulation',
      title: 'Simulation',
      description: 'Games simulating real-life activities, like building cities, managing farms, or piloting'
    },
    {
      value: 'rpg',
      title: 'Role-Playing (RPG)',
      description: 'Games with character progression, quests, and immersive narratives'
    },
    {
      value: 'sports',
      title: 'Sports',
      description: 'Games based on sports like soccer, basketball, cricket, or racing'
    },
    {
      value: 'casual',
      title: 'Casual',
      description: 'Easy-to-play games aimed at relaxation, often suitable for all ages'
    },
    {
      value: 'multiplayer',
      title: 'Multiplayer',
      description: 'Games focused on social interaction and competition with other players'
    },
    {
      value: 'educational',
      title: 'Educational',
      description: 'Games designed for learning or skill-building, often targeted at children'
    },
    {
      value: 'horror',
      title: 'Horror',
      description: 'Games focused on fear, suspense, and thrilling experiences'
    },
    {
      value: 'card_board',
      title: 'Card & Board',
      description: 'Digital versions of card games, chess, or other board games'
    },
    {
      value: 'trivia',
      title: 'Trivia',
      description: 'Quiz-style games to test knowledge or memory'
    },
    {
      value: 'other',
      title: 'Other',
      description: 'Specify your own category'
    }
  ];

  return (
    <div className={styles.formGroup}>
      <label>Categories * (You can select multiple categories)</label>
      <div className={styles.customSelect}>
        {categories.map(category => (
          <div
            key={category.value}
            className={`${styles.categoryOption} ${
              selectedCategories.includes(category.value) ? styles.selected : ''
            }`}
            onClick={() => !disabled && onCategoryChange(category.value)}
          >
            <div className={styles.categoryContent}>
              <span className={styles.categoryTitle}>{category.title}</span>
              <span className={styles.categoryDesc}>({category.description})</span>
            </div>
          </div>
        ))}
      </div>
      
      {selectedCategories.includes('other') && (
        <div className={styles.customCategoryWrapper}>
          <label className={styles.customCategoryLabel}>
            Please specify your category:
          </label>
          <input
            type="text"
            value={customCategory}
            onChange={(e) => onCustomCategoryChange(e.target.value)}
            className={styles.customCategoryInput}
            placeholder="Enter your category name"
            required
            disabled={disabled}
          />
        </div>
      )}

      {selectedCategories.length > 0 && (
        <div className={styles.selectedCategories}>
          <strong>Selected:</strong>{' '}
          {selectedCategories.map(value => {
            const category = categories.find(cat => cat.value === value);
            return category ? (
              value === 'other' && customCategory 
                ? `Other (${customCategory})`
                : category.title
            ) : value;
          }).join(', ')}
        </div>
      )}
    </div>
  );
};

const AcctAppDetailsComponent = ({ game }) => {
  const [activeTab, setActiveTab] = useState(1);
  const navigate = useNavigate();
  
  // Initialize state with game prop if it exists and has a game_id
  const [gameName, setGameName] = useState(game?.game_id ? game.name : '');
  const [selectedCategories, setSelectedCategories] = useState(game?.game_id ? game.categories : []);
  const [shortDescription, setShortDescription] = useState(game?.game_id ? game.short_description : '');
  const [detailedDescription, setDetailedDescription] = useState(game?.game_id ? game.detailed_description : '');
  const [gameIcon, setGameIcon] = useState(null);
  const [gameIconName, setGameIconName] = useState(game?.game_id ? game.app_icon_name : '');
  const [backgroundImage, setBackgroundImage] = useState(null);
  const [backgroundImageName, setBackgroundImageName] = useState(game?.game_id ? game.background_image_name : '');
  const [customCategory, setCustomCategory] = useState(game?.game_id ? game.custom_category : '');

  // Page 2 - Technical Details
  const [platforms, setPlatforms] = useState(game?.game_id ? game.platforms : {
    ios: false,
    android: false,
    native: false
  });
  const [appStoreLink, setAppStoreLink] = useState(game?.game_id ? game.app_store_link : '');
  const [playStoreLink, setPlayStoreLink] = useState(game?.game_id ? game.play_store_link : '');
  const [nativeAppFile, setNativeAppFile] = useState(null);
  const [nativeAppFileName, setNativeAppFileName] = useState(game?.game_id ? game.native_app_file_name : '');
  const [supportedDevices, setSupportedDevices] = useState(game?.game_id ? game.supported_devices : '');
  const [ageRating, setAgeRating] = useState(game?.game_id ? game.age_rating : '');
  const [price, setPrice] = useState(game?.game_id ? game.price_type : 'free');
  const [priceAmount, setPriceAmount] = useState(game?.game_id ? game.price_amount : '');
  const [hasInAppPurchases, setHasInAppPurchases] = useState(game?.game_id ? game.has_in_app_purchases : false);
  const [hasAds, setHasAds] = useState(game?.game_id ? game.has_ads : false);
  const [supportEmail, setSupportEmail] = useState(game?.game_id ? game.support_email : '');
  const [agreedToTerms, setAgreedToTerms] = useState(game?.game_id ? game.agreed_to_terms : false);
  const [countryRestrictions, setCountryRestrictions] = useState(game?.game_id ? game.country_restrictions : {
    type: 'include',
    countries: []
  });

  // New state to control editability and game existence
  const [editDisabled, setEditDisabled] = useState(!!game?.game_id);
  const [gameExists, setGameExists] = useState(!!game?.game_id);

  const iconInputRef = useRef(null);
  const backgroundImageInputRef = useRef(null);

  const handleTabChange = (tabNumber) => {
    setActiveTab(tabNumber);
  };

  const handleFileChange = (setter, setName) => (e) => {
    const file = e.target.files[0];
    setter(file);
    setName(file ? file.name : '');
  };

  const handleBackgroundImageChange = (e) => {
    const file = e.target.files[0];
    setBackgroundImage(file);
    setBackgroundImageName(file ? file.name : '');
  };

  const handlePlatformChange = (platform) => {
    setPlatforms(prev => ({
      ...prev,
      [platform]: !prev[platform]
    }));
  };

  const handleCategoryChange = (categoryValue) => {
    setSelectedCategories(prev => {
      if (prev.includes(categoryValue)) {
        return prev.filter(cat => cat !== categoryValue);
      } else {
        return [...prev, categoryValue];
      }
    });
  };

  const handleEdit = (e) => {
    e.preventDefault();
    e.stopPropagation();
   // console.log('Edit button clicked');
    setEditDisabled(false);
  };

  const handleCancel = () => {
    setEditDisabled(true);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
  //  console.log('Save button clicked');

    // Check if the file inputs are required and have a file selected
    if (!gameExists) {
      if (!gameIcon) {
        alert("Please upload a game icon.");
        return;
      }

      if (!backgroundImage) {
        alert("Please upload a background image.");
        return;
      }
    }

    const formData = new FormData();
    
    // Basic game details
    formData.append('name', gameName);
    formData.append('categories', JSON.stringify(selectedCategories));
    formData.append('short_description', shortDescription);
    formData.append('detailed_description', detailedDescription);

    // File handling - similar to UpdatePage
    if (gameIcon instanceof File) {
      formData.append('app_icon', gameIcon);
    }
    
    if (backgroundImage instanceof File) {
      formData.append('background_image', backgroundImage);
    }

    if (nativeAppFile instanceof File) {
      formData.append('native_app_file', nativeAppFile);
    }

    // Technical details
    formData.append('platforms', JSON.stringify(platforms));
    if (platforms.ios) formData.append('app_store_link', appStoreLink);
    if (platforms.android) formData.append('play_store_link', playStoreLink);
    
    formData.append('supported_devices', supportedDevices);
    formData.append('age_rating', ageRating);
    formData.append('price_type', price);
    if (price === 'paid') formData.append('price_amount', priceAmount);
    formData.append('has_in_app_purchases', hasInAppPurchases.toString());
    formData.append('has_ads', hasAds.toString());
    formData.append('support_email', supportEmail);
    formData.append('agreed_to_terms', agreedToTerms.toString());
    formData.append('country_restrictions', JSON.stringify(countryRestrictions));

    try {
      const accessToken = await localStorage.getItem('accessToken');
      const endpoint = gameExists ? `/v1/games/${game.game_id}` : '/v1/games/';
      const method = gameExists ? 'put' : 'post'; // Use PUT for updating existing games

      const response = await devClient[method](endpoint, formData, {
        headers: {
          'Authorization': `Bearer ${accessToken}`,
        }
      });
      
      console.log('Game submitted successfully:', response.data);
      navigate('/35lz6vm87q425vd9-portfolio');
    } catch (error) {
      console.error('Error submitting game:', error.response?.data || error);
    }
  };

  return (
    <div className={styles.uploadPage}>
      <div className={styles.tabs}>
        <div 
          className={`${styles.tab} ${activeTab === 1 ? styles.active : ''}`}
          onClick={() => handleTabChange(1)}
        >
          Game Details
        </div>
        <div 
          className={`${styles.tab} ${activeTab === 2 ? styles.active : ''}`}
          onClick={() => handleTabChange(2)}
        >
          Technical Details
        </div>
      </div>

      <form onSubmit={handleSubmit}>
        {activeTab === 1 && (
          <div className={styles.tabContent}>
            <div className={styles.formContent}>
              <section className={styles.basicInfo}>
                <h2>Basic Information</h2>
                
                <div className={styles.formGroup}>
                  <label>Game Name *</label>
                  <input
                    type="text"
                    value={gameName}
                    onChange={(e) => setGameName(e.target.value)}
                    placeholder="Enter game name"
                    required
                    disabled={editDisabled}
                  />
                </div>

                <CategorySelect 
                  selectedCategories={selectedCategories}
                  onCategoryChange={handleCategoryChange}
                  customCategory={customCategory}
                  onCustomCategoryChange={setCustomCategory}
                  disabled={editDisabled}
                />

                <div className={styles.formGroup}>
                  <label>Short Description *</label>
                  <textarea
                    value={shortDescription}
                    onChange={(e) => setShortDescription(e.target.value)}
                    placeholder="Brief description of your game (max 150 characters)"
                    maxLength={150}
                    required
                    disabled={editDisabled}
                  />
                </div>

                <div className={styles.formGroup}>
                  <label>Detailed Description</label>
                  <textarea
                    value={detailedDescription}
                    onChange={(e) => setDetailedDescription(e.target.value)}
                    placeholder="Detailed description of your game"
                    rows={6}
                    disabled={editDisabled}
                  />
                </div>

                <div className={styles.formGroup}>
                  <label>Game Icon *</label>
                  <div className={styles.uploadSection}>
                    <input
                      type="file"
                      ref={iconInputRef}
                      onChange={handleFileChange(setGameIcon, setGameIconName)}
                      accept="image/*"
                      className={styles.fileInput}
                      required={!gameExists}
                      style={{ display: 'none' }}
                      disabled={editDisabled}
                    />
                    <div 
                      className={styles.uploadContent}
                      onClick={() => !editDisabled && iconInputRef.current?.click()}
                    >
                      <FontAwesomeIcon icon={faImage} className={styles.uploadIcon} />
                      <p>Click to upload game icon</p>
                      {gameIconName && <p className={styles.fileName}>{gameIconName}</p>}
                      <span className={styles.uploadHint}>
                        Recommended: 512x512px PNG
                      </span>
                    </div>
                  </div>
                </div>

                <div className={styles.formGroup}>
                  <label>Background Image *</label>
                  <div className={styles.uploadSection}>
                    <input
                      type="file"
                      ref={backgroundImageInputRef}
                      onChange={handleBackgroundImageChange}
                      accept="image/*"
                      className={styles.fileInput}
                      required={!gameExists}
                      style={{ display: 'none' }}
                      disabled={editDisabled}
                    />
                    <div 
                      className={styles.uploadContent}
                      onClick={() => !editDisabled && backgroundImageInputRef.current?.click()}
                    >
                      <FontAwesomeIcon icon={faImage} className={styles.uploadIcon} />
                      <p>Click to upload background image</p>
                      {backgroundImageName && <p className={styles.fileName}>{backgroundImageName}</p>}
                      <span className={styles.uploadHint}>
                        Recommended: 1920x1080px JPG or PNG
                      </span>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>
        )}

        {activeTab === 2 && (
          <div className={styles.tabContent}>
            <div className={styles.formContent}>
              <section className={styles.technicalDetails}>
                <h2>Technical Details</h2>

                <div className={styles.formGroup}>
                  <label>Platform(s) *</label>
                  <div className={styles.platformOptions}>
                    <label className={styles.platformCheckbox}>
                      <input
                        type="checkbox"
                        checked={platforms.ios}
                        onChange={(e) => setPlatforms(prev => ({
                          ...prev,
                          ios: e.target.checked
                        }))}
                        disabled={editDisabled}
                      />
                      <span>iOS</span>
                    </label>

                    <label className={styles.platformCheckbox}>
                      <input
                        type="checkbox"
                        checked={platforms.android}
                        onChange={(e) => setPlatforms(prev => ({
                          ...prev,
                          android: e.target.checked
                        }))}
                        disabled={editDisabled}
                      />
                      <span>Android</span>
                    </label>

                    <label className={styles.platformCheckbox}>
                      <input
                        type="checkbox"
                        checked={platforms.native}
                        onChange={(e) => setPlatforms(prev => ({
                          ...prev,
                          native: e.target.checked
                        }))}
                        disabled={editDisabled}
                      />
                      <span>Native Store App</span>
                    </label>
                  </div>

                  {platforms.ios && (
                    <div className={styles.platformField}>
                      <label>App Store Link *</label>
                      <input
                        type="url"
                        value={appStoreLink}
                        onChange={(e) => setAppStoreLink(e.target.value)}
                        placeholder="Enter App Store URL"
                        required={platforms.ios}
                        disabled={editDisabled}
                      />
                    </div>
                  )}

                  {platforms.android && (
                    <div className={styles.platformField}>
                      <label>Play Store Link *</label>
                      <input
                        type="url"
                        value={playStoreLink}
                        onChange={(e) => setPlayStoreLink(e.target.value)}
                        placeholder="Enter Play Store URL"
                        required={platforms.android}
                        disabled={editDisabled}
                      />
                    </div>
                  )}

                  {platforms.native && (
                    <div className={styles.platformField}>
                      <label>Upload Game File (ZIP) *</label>
                      <div className={styles.uploadSection}>
                        <input
                          type="file"
                          accept=".zip"
                          onChange={(e) => setNativeAppFile(e.target.files[0])}
                          required={platforms.native}
                          disabled={editDisabled}
                        />
                        {nativeAppFileName && (
                          <div className={styles.fileName}>
                            {nativeAppFileName}
                          </div>
                        )}
                      </div>
                    </div>
                  )}
                </div>

                <div className={styles.formGroup}>
                  <label>Supported Devices</label>
                  <input
                    type="text"
                    value={supportedDevices}
                    onChange={(e) => setSupportedDevices(e.target.value)}
                    placeholder="e.g., iPhone 6S and above, Android 8.0+"
                    disabled={editDisabled}
                  />
                </div>

                <div className={styles.formGroup}>
                  <label>Age Rating *</label>
                  <select
                    value={ageRating}
                    onChange={(e) => setAgeRating(e.target.value)}
                    required
                    disabled={editDisabled}
                  >
                    <option value="">Select Age Rating</option>
                    <option value="everyone">Everyone</option>
                    <option value="7+">7+</option>
                    <option value="12+">12+</option>
                    <option value="18+">18+</option>
                  </select>
                </div>

                <div className={styles.formGroup}>
                  <label>Country Restrictions</label>
                  <div className={styles.countryRestrictionSection}>
                    <div className={styles.restrictionType}>
                      <label className={styles.radioLabel}>
                        <input
                          type="radio"
                          name="restrictionType"
                          value="include"
                          checked={countryRestrictions.type === 'include'}
                          onChange={(e) => setCountryRestrictions(prev => ({
                            ...prev,
                            type: e.target.value
                          }))}
                          disabled={editDisabled}
                        />
                        Include Selected Countries
                      </label>
                      <label className={styles.radioLabel}>
                        <input
                          type="radio"
                          name="restrictionType"
                          value="exclude"
                          checked={countryRestrictions.type === 'exclude'}
                          onChange={(e) => setCountryRestrictions(prev => ({
                            ...prev,
                            type: e.target.value
                          }))}
                          disabled={editDisabled}
                        />
                        Exclude Selected Countries
                      </label>
                    </div>
                    
                    <select
                      multiple
                      value={countryRestrictions.countries}
                      onChange={(e) => setCountryRestrictions(prev => ({
                        ...prev,
                        countries: Array.from(e.target.selectedOptions, option => option.value)
                      }))}
                      className={styles.multiSelect}
                      disabled={editDisabled}
                    >
                      <option value="US">United States</option>
                      <option value="GB">United Kingdom</option>
                      <option value="CA">Canada</option>
                      <option value="AU">Australia</option>
                      <option value="IN">India</option>
                      <option value="JP">Japan</option>
                      {/* Add more countries as needed */}
                    </select>
                    
                    {countryRestrictions.countries.length > 0 && (
                      <div className={styles.selectedCountries}>
                        {countryRestrictions.type === 'include' ? 'Including' : 'Excluding'}: {
                          countryRestrictions.countries.join(', ')
                        }
                      </div>
                    )}
                  </div>
                </div>

                <div className={styles.formGroup}>
                  <label>Price *</label>
                  <div className={styles.priceSection}>
                    <select
                      value={price}
                      onChange={(e) => setPrice(e.target.value)}
                      required
                      disabled={editDisabled}
                    >
                      <option value="free">Free</option>
                      <option value="paid">Paid</option>
                    </select>
                    {price === 'paid' && (
                      <input
                        type="number"
                        value={priceAmount}
                        onChange={(e) => setPriceAmount(e.target.value)}
                        placeholder="Enter price in USD"
                        min="0.99"
                        step="0.01"
                        required
                        disabled={editDisabled}
                      />
                    )}
                  </div>
                </div>

                <div className={styles.formGroup}>
                  <label className={styles.checkboxContainer}>
                    <input
                      type="checkbox"
                      checked={hasInAppPurchases}
                      onChange={(e) => setHasInAppPurchases(e.target.checked)}
                      disabled={editDisabled}
                    />
                    <span className={styles.checkboxLabel}>Contains In-App Purchases</span>
                  </label>
                </div>

                <div className={styles.formGroup}>
                  <label className={styles.checkboxContainer}>
                    <input
                      type="checkbox"
                      checked={hasAds}
                      onChange={(e) => setHasAds(e.target.checked)}
                      disabled={editDisabled}
                    />
                    <span className={styles.checkboxLabel}>Contains Advertisements</span>
                  </label>
                </div>

                <div className={styles.formGroup}>
                  <label>Support Email *</label>
                  <input
                    type="email"
                    value={supportEmail}
                    onChange={(e) => setSupportEmail(e.target.value)}
                    placeholder="Enter support email"
                    required
                    disabled={editDisabled}
                  />
                </div>

                <div className={styles.formGroup}>
                  <label className={styles.checkboxContainer}>
                    <input
                      type="checkbox"
                      checked={agreedToTerms}
                      onChange={(e) => setAgreedToTerms(e.target.checked)}
                      required
                      disabled={editDisabled}
                    />
                    <span className={styles.checkboxLabel}>
                      I agree to the terms, privacy policies, and compliance requirements
                    </span>
                  </label>
                </div>
              </section>
            </div>
          </div>
        )}

        <div className={styles.buttonContainer}>
          {editDisabled ? (
            <button type="button" className={styles.editBtn} onClick={handleEdit}>
              Edit
            </button>
          ) : (
            <>
              <button type="submit" className={styles.saveBtn}>
                Save
              </button>
              {gameExists && (
                <button type="button" className={styles.cancelBtn} onClick={handleCancel}>
                  Cancel
                </button>
              )}
            </>
          )}
        </div>
      </form>
    </div>
  );
};

export default AcctAppDetailsComponent;
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styles from './AcctLoginPage.module.css'; // Import the CSS module
import HeaderBar from './AcctHeaderBar';
import { useAuth } from '../context/AuthContext';

const LoginPage = () => {
  const [credentials, setCredentials] = useState({ username: '', password: '' });
  const { login } = useAuth();
  const navigate = useNavigate();

  const handleLogin = async (e) => {
    e.preventDefault();
    await login(credentials);
    console.log("Login successful");

    console.log("AccessToken stored: ", localStorage.getItem('accessToken'));
    navigate('/35lz6vm87q425vd9-portfolio');
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setCredentials((prevCredentials) => ({
      ...prevCredentials,
      [name]: value,
    }));
  };

  const handleSignUp = () => {
    navigate('/create-account');
  };

  const handleForgotPassword = () => {
    // Navigate to forgot password page
    navigate('/forgot-password');
  };

  const menuItems = [
    // { type: 'link', label: 'Docs', href: '#' },
    // { type: 'link', label: 'API Reference', href: '#' },
    // { type: 'link', label: 'Learn', href: '#' },
    // { type: 'link', label: 'Community', href: '#' },
  ];

  return (
    <div className={styles.loginPage}>
      <HeaderBar menuItems={menuItems} />
      <main className={styles.mainContent}>
        <div className={styles.headingSection}>
          <h1>Welcome back to Maze Developer Portal</h1>
          <p>Log in to continue collaborating and access to developer portal</p>
        </div>
        <form className={styles.loginForm} onSubmit={handleLogin}>
          <label htmlFor="username">Username</label>
          <input
            type="text"
            name="username"  
            id="username"
            value={credentials.username}
            onChange={handleChange}
            placeholder="Username"
          />
          <label htmlFor="password">Password</label>
          <input
            type="password"
            name="password"
            value={credentials.password}
            onChange={handleChange}
            placeholder="Password"
          />
    
          <button type="submit" className={styles.loginBtn}>Log in</button>
          <div className={styles.links}>
            <a href="#" onClick={handleForgotPassword}>Forgot password?</a>
            <span>Don't have an account? <a href="#" onClick={handleSignUp}>Sign up</a></span>
          </div>
        </form>
      </main>
    </div>
  );
};

export default LoginPage;

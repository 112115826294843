import React from 'react';
import { useLocation } from 'react-router-dom';
import AcctAppDetailsComponent from '../components/developer/AcctAppDetailsComponent';
import AcctHeaderBar from '../components/developer/AcctHeaderBar';
import FooterBar from '../components/common/FooterBar';

const AcctAppDetailsPage = () => {
  const location = useLocation();
  const { game } = location.state || {};

  console.log('Game prop:', game);

  const menuItems = [
    // Add menu items if needed
  ];

  return (
    <div>
      <AcctHeaderBar menuItems={menuItems} isSignedIn={true} />
      <AcctAppDetailsComponent game={game} />
      <FooterBar />
    </div>
  );
};

export default AcctAppDetailsPage; 
import React, { useState, useRef, useEffect } from 'react';
import styles from './TermsModal.module.css';

const PrivacyPolicyModal = ({ isOpen, onClose }) => {
  const [showCloseButton, setShowCloseButton] = useState(false);
  const contentRef = useRef(null);

  const handleScroll = () => {
    if (contentRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = contentRef.current;
      const isAtBottom = scrollTop + clientHeight >= scrollHeight - 20;
      setShowCloseButton(isAtBottom);
    }
  };

  useEffect(() => {
    if (isOpen && contentRef.current) {
      contentRef.current.scrollTop = 0;
      setShowCloseButton(false);
    }
  }, [isOpen]);

  if (!isOpen) return null;

  return (
    <div className={styles.modalOverlay}>
      <div className={styles.modal}>
        <div className={styles.modalHeader}>
          <h2>Privacy Policy</h2>
          <button 
            className={styles.closeIcon}
            onClick={onClose}
            aria-label="Close"
          >
            ×
          </button>
        </div>

        <div 
          ref={contentRef}
          className={styles.modalContent}
          onScroll={handleScroll}
        >
          <h3>1. Introduction</h3>
          <p>This Privacy Policy describes how we collect, use, process, and disclose your information across our platform.</p>

          <h3>2. Information We Collect</h3>
          <p>We collect several types of information from and about users of our platform, including:</p>
          <ul>
            <li><strong>Personal Information:</strong> Name, email address, phone number, and other identifiers</li>
            <li><strong>Account Information:</strong> Login credentials and profile information</li>
            <li><strong>Usage Data:</strong> Information about how you use our platform</li>
            <li><strong>Device Information:</strong> Web browser, IP address, and device identifiers</li>
          </ul>

          <h3>3. How We Use Your Information</h3>
          <p>We use the information we collect to:</p>
          <ul>
            <li>Provide, maintain, and improve our services</li>
            <li>Process your transactions and send related information</li>
            <li>Send you technical notices and support messages</li>
            <li>Communicate with you about products, services, and events</li>
            <li>Monitor and analyze trends and usage</li>
          </ul>

          <h3>4. Developer Content and Applications</h3>
          <p>When you upload applications and assets to our platform:</p>
          <ul>
            <li><strong>Content Storage:</strong>
              <ul>
                <li>Your uploads are stored in secure cloud infrastructure</li>
                <li>We maintain encryption for data in transit and at rest</li>
                <li>Regular backups are performed to prevent data loss</li>
                <li>Access to your content is protected by authentication</li>
              </ul>
            </li>
            <li><strong>Usage Analytics:</strong>
              <ul>
                <li>We collect data about how your applications are accessed</li>
                <li>Download and usage statistics are provided</li>
                <li>Performance metrics and error reports are available</li>
                <li>Geographic distribution of users is tracked</li>
              </ul>
            </li>
            <li><strong>Content Distribution:</strong>
              <ul>
                <li>Your content is distributed through our global CDN network</li>
                <li>Access logs are maintained for security purposes</li>
                <li>Traffic patterns are monitored for optimization</li>
              </ul>
            </li>
          </ul>

          <h3>5. Developer Dashboard Data</h3>
          <p>Through our developer dashboard, we collect:</p>
          <ul>
            <li>Application performance metrics</li>
            <li>User engagement statistics</li>
            <li>Error and crash reports</li>
            <li>API usage data</li>
            <li>Integration logs</li>
          </ul>
          <p>This data is used to provide you with insights and improve our services.</p>

          <h3>6. Information Sharing and Disclosure</h3>
          <p>We may share your information with:</p>
          <ul>
            <li>Service providers who assist in our operations</li>
            <li>Professional advisors and law enforcement when necessary</li>
            <li>Third parties in connection with a business transfer</li>
          </ul>

          <h3>7. Data Security</h3>
          <p>We implement appropriate technical and organizational measures to protect your personal information against unauthorized or unlawful processing, accidental loss, destruction, or damage.</p>

          <h3>8. Your Rights and Choices</h3>
          <p>You have the right to:</p>
          <ul>
            <li>Access your personal information</li>
            <li>Correct inaccurate data</li>
            <li>Request deletion of your data</li>
            <li>Object to processing of your data</li>
            <li>Request data portability</li>
          </ul>

          <h3>9. Cookies and Tracking Technologies</h3>
          <p>We use cookies and similar tracking technologies to track activity on our platform and hold certain information. You can instruct your browser to refuse all cookies or to indicate when a cookie is being sent.</p>

          <h3>10. Children's Privacy</h3>
          <p>Our services are not intended for children under 13 years of age. We do not knowingly collect personal information from children under 13.</p>

          <h3>11. International Data Transfers</h3>
          <p>Your information may be transferred to and processed in countries other than your country of residence. We will take appropriate measures to protect your personal information in accordance with this Privacy Policy.</p>

          <h3>12. Changes to This Policy</h3>
          <p>We may update this Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page and updating the "Last Updated" date.</p>

          <h3>13. Data Retention</h3>
          <p>We retain your personal information for as long as necessary to fulfill the purposes outlined in this Privacy Policy, unless a longer retention period is required by law.</p>

          <h3>14. Contact Us</h3>
          <p>If you have any questions about this Privacy Policy, please contact us at:</p>
          <p>Email: support@maze.social</p>
          <p>Address: Bangalore, Karnataka, India</p>

          <p><strong>Last Updated:</strong> November 18, 2024</p>
        </div>

        <div className={styles.modalFooter}>
          {showCloseButton && (
            <button 
              className={styles.acceptButton}
              onClick={onClose}
            >
              Close
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default PrivacyPolicyModal; 
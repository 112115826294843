import React from 'react';
import styles from './DeveloperHomePage.module.css'; // Import the CSS module
import  HeaderBar from './AcctHeaderBar'; // Import the HeaderNavBar component

import api_icn from '../../assets/icons/api.png'; 
import dist_icn from '../../assets/icons/distribute.png'; 
import eng_icn from '../../assets/icons/engage.png'; 
import web_icn from '../../assets/icons/web.png'; 
import FooterBar from '../common/FooterBar';

const DevPage = () => {

  
  const menuItems = [
    // { type: 'link', label: 'Documentation', href: '#' },
    { type: 'link', label: 'Contact', href: '/developer-contact' },
  ];

  const createAccountFn = () => {
    window.location.href = '/login';
  };

  return (
    <div className={styles.DevPage}>
      <HeaderBar menuItems={menuItems} isSignedIn={false} />
      <main>
        <div className={styles.hero}>
          <div className={styles.heroContent}>
            <h1>Distribute & Integrate with Maze</h1>
            <p>Publish your games to reach global audience and enhance them with powerful integration tools.</p>
          </div>

          <div className={styles.heroBackground}>
            {/* Placeholder for background SVG */}
          </div>

          <section className={styles.cta}>
            <button className={styles.createAccountBtn} onClick={createAccountFn}>Getting Started</button>
            <button className={styles.contactSalesBtn} onClick={() => window.location.href = '/developer-contact'}>Contact</button>
          </section>
        </div>

        <section className={styles.features}>
          <h2>Ways to Grow with Maze</h2>
          <div className={styles.featureCards}>
            <div className={styles.featureCard}>
              <img src={dist_icn} alt="Distribution" />
              <h3>Game Store Distribution</h3>
              <p>Publish your games on our store and reach millions of players worldwide through our curated marketplace.</p>
            </div>
            <div className={styles.featureCard}>
              <img src={api_icn} alt="Integration" />
              <h3>Developer Integration</h3>
              <p>Access our SDK and APIs to integrate Maze features directly into your games and enhance player experience.</p>
            </div>
            <div className={styles.featureCard}>
              <img src={eng_icn} alt="Features" />
              <h3>Powerful Features</h3>
              <p>Leverage achievements, leaderboards, social features, and more to boost player engagement.</p>
            </div>
            <div className={styles.featureCard}>
              <img src={web_icn} alt="Analytics" />
              <h3>Analytics & Insights</h3>
              <p>Track performance, understand your audience, and optimize your game's success with detailed analytics.</p>
            </div>
          </div>
        </section>

      </main>
      <FooterBar/>
    </div>
  );
}

export default DevPage;

// src/components/PrivateRoute.js
import React from 'react';
import { Navigate } from 'react-router-dom';
import {useAuth } from './AuthContext';

const PrivateRoute = ({ component: Component, allowAccess, publicComponent }) => {
  const { currentUser } = useAuth();
  console.log("Current user ", currentUser);

  // If publicComponent is provided and user is not logged in, show that instead
  if (!currentUser && publicComponent) {
    return publicComponent;
  }

  if (!currentUser) {
    return <Navigate to="/login" />;
  }

  console.log("Is user eligible: ", currentUser.eligible == 1 )

  // Check if access is allowed
  if (allowAccess && !allowAccess(currentUser)) {
    return <Navigate to="/developer-approval" />;
  }

  return Component;
}

export default PrivateRoute;

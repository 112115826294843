import React, { useState } from 'react';
import styles from './AcctCreatePage.module.css'; // Import the CSS module
import  AcctHeaderBar from './AcctHeaderBar'; // Import the HeaderNavBar component
import FooterBar from '../common/FooterBar';
import { devClient } from '../api/client';
import TermsModal from '../common/TermsModal';
import PrivacyPolicyModal from '../common/PrivacyPolicyModal';

const CreateAccountPage = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    username: '',
    password: '',
    organization: '',
    profilePictureUpload: null,
    bio: '',
    confirmPassword: '',
    country: '',
    websiteUrl: '',
    agreeToTerms: false
  });

  const [isTermsModalOpen, setIsTermsModalOpen] = useState(false);
  const [isPrivacyModalOpen, setIsPrivacyModalOpen] = useState(false);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: type === 'checkbox' ? checked : value
    }));
  };

  const handleFileChange = (e) => {
    const { name, files } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: files[0]
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!formData.agreeToTerms) {
      alert('Please agree to the Terms and Conditions');
      return;
    }

    if (formData.password !== formData.confirmPassword) {
      alert('Passwords do not match');
      return;
    }

    const data = new FormData();
    for (const key in formData) {
      data.append(key, formData[key]);
    }

    try {
      const response = await devClient.post('/v1/developer/create/', data);
      console.log("Response: ", response)
      if (response.status == 201) {
        alert("Account created successfully!");
        window.location.href = '/login';
      } else {
        console.error('Failed to create account.');
       // alert('Failed to create account.');
      }
    } catch (error) {
      console.error('Error:', error);
    }

  };

    const menuItems = [
      // { type: 'link', label: 'Documentation', href: '#' },
    ];

  return (
    <div className={styles.createAccountPage}>
      <AcctHeaderBar menuItems={menuItems} isSignedIn={false} />
      <main className={styles.mainContent}>
        <h1>Create your account</h1>
        <p className={styles.subtitle}>Create an account to view and manage your projects.</p>
        <form className={styles.createAccountForm} onSubmit={handleSubmit}>
          <div className={styles.formGroup}>
            <label htmlFor="name">Name</label>
            <input
              type="text"
              id="name"
              name="name"
              placeholder="Your name"
              value={formData.name}
              onChange={handleChange}
            />
          </div>
          <div className={styles.formGroup}>
            <label htmlFor="email">Email</label>
            <input
              type="text"
              id="email"
              name="email"
              placeholder="Email"
              value={formData.email}
              onChange={handleChange}
            />
          </div>
          <div className={styles.formGroup}>
            <label htmlFor="organization">Organization (optional)</label>
            <input
              type="text"
              id="organization"
              name="organization"
              placeholder="Your company, team, or organization"
              value={formData.organization}
              onChange={handleChange}
            />
          </div>
          <div className={styles.formGroup}>
            <label htmlFor="profilePictureUpload">Logo (optional)</label>
            <input
              type="file"
              id="profilePictureUpload"
              name="profilePictureUpload"
              onChange={handleFileChange}
              className={styles.uploadButton}
            />
          </div>
          <div className={styles.formGroup}>
            <label htmlFor="bio">Bio (optional)</label>
            <textarea
              id="bio"
              name="bio"
              placeholder="Write a short bio"
              value={formData.bio}
              onChange={handleChange}
              rows="5"
            ></textarea>
          </div>
          <div className={styles.formGroup}>
            <label htmlFor="username">Username</label>
            <input
              type="text"
              id="username"
              name="username"
              placeholder="Your username"
              value={formData.username}
              onChange={handleChange}
            />
          </div>
          <div className={styles.formGroup}>
            <label htmlFor="password">Password</label>
            <input
              type="password"
              id="password"
              name="password"
              placeholder="Your password"
              value={formData.password}
              onChange={handleChange}
            />
          </div>
          <div className={styles.formGroup}>
            <label htmlFor="confirmPassword">Confirm Password</label>
            <input
              type="password"
              id="confirmPassword"
              name="confirmPassword"
              placeholder="Confirm your password"
              value={formData.confirmPassword}
              onChange={handleChange}
            />
          </div>
          <div className={styles.formGroup}>
            <label htmlFor="country">Country/Region</label>
            <input
              type="text"
              id="country"
              name="country"
              placeholder="Your country or region"
              value={formData.country}
              onChange={handleChange}
              required
            />
          </div>
          <div className={styles.formGroup}>
            <label htmlFor="websiteUrl">Website/Portfolio Link (optional)</label>
            <input
              type="url"
              id="websiteUrl"
              name="websiteUrl"
              placeholder="https://your-website.com"
              value={formData.websiteUrl}
              onChange={handleChange}
            />
          </div>
          <div className={styles.formGroup}>
            <label className={styles.checkboxLabel}>
              <input
                type="checkbox"
                name="agreeToTerms"
                checked={formData.agreeToTerms}
                onChange={handleChange}
              />
              <span>
                I agree to the{' '}
                <a
                  href="#"
                  onClick={(e) => {
                    e.preventDefault();
                    setIsTermsModalOpen(true);
                  }}
                >
                  Terms and Conditions
                </a>
                {' '}and{' '}
                <a
                  href="#"
                  onClick={(e) => {
                    e.preventDefault();
                    setIsPrivacyModalOpen(true);
                  }}
                >
                  Privacy Policy
                </a>
              </span>
            </label>
          </div>
          <button 
            type="submit" 
            className={styles.createAccountButton}
            disabled={!formData.agreeToTerms}
          >
            Create your account
          </button>
        </form>
      </main>
      
      <FooterBar/>

      <TermsModal 
        isOpen={isTermsModalOpen}
        onClose={() => setIsTermsModalOpen(false)}
      />
      
      <PrivacyPolicyModal 
        isOpen={isPrivacyModalOpen}
        onClose={() => setIsPrivacyModalOpen(false)}
      />
    </div>
  );
};

export default CreateAccountPage;

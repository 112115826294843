// src/App.js
import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import AcctLoginPage from './components/developer/AcctLoginPage';
import ForgotPasswordPage from './components/developer/AcctForgotPassPage';
import AcctAppCreatePage from './components/developer/AcctAppCreatePage';
import AcctAppUpdatePage from './components/developer/AcctAppUpdatePage';
import DeveloperApprovalPage from './components/developer/AcctDevApprovalPage';
import DeveloperHomePage from './components/developer/DeveloperHomePage';
import AcctPortfolioPage from './components/developer/AcctPortfolioPage';
import AcctAppPreviewPage from './components/developer/AcctAppPreviewPage';
import AcctCreatePage from './components/developer/AcctCreatePage';
import AcctDetailsPage from './components/developer/AcctDetailsPage';
import { AuthProvider } from './components/context/AuthContext';
import PasswordResetSuccessful from './components/developer/AcctPassResetSuccess';
import AdminPage from './components/admin/AdminPage';
import Homepage from './components/public/Homepage';
import PrivateRoute from './components/context/PrivateRoute';
import MazeGame from './components/game/MazeGame';
import DeveloperContact from './components/developer/DeveloperContact';
import BallAnimation from './components/game/BallAnimation';
import GameInfoPage from './components/developer/GameInfoPage';
import AcctAppDetailsPage from './pages/AcctAppDetailsPage';
const App = () => {
  const hostname = window.location.hostname;

  let homeComponent, loginRoute, forgotPassRoute, devApprovalRoute, passResetRoute, createAppRoute, 
  updateAppRoute, portfolioRoute, previewRoute, createAcctRoute, acctDetailsRoute, gameInfoRoute, acctAppDetailsRoute;
  let tempHomeRoute, tempAdminRoute, tempGameRoute, tempDevContactRoute, tempBallAnimationRoute;

  const eligibilityCheck = (user) => {
    return user.eligible == 1; 
  };
  
  if (hostname.includes('dev')) {
  //if (hostname.includes('dev')) {
    homeComponent = DeveloperHomePage;
    tempHomeRoute = <Route path="/" element={
      <PrivateRoute 
        component={<Navigate to="/35lz6vm87q425vd9-portfolio" />} 
        publicComponent={<DeveloperHomePage />}
      />
    } /> ;
    loginRoute = <Route path="/login" element={<AcctLoginPage />} /> ;
    forgotPassRoute = <Route path="/forgot-password" element={<ForgotPasswordPage />} /> ;
    devApprovalRoute = <Route path="/developer-approval"  element={<PrivateRoute component={<DeveloperApprovalPage />} />} /> ;
    passResetRoute = <Route path="/password-reset-successful" element={<PasswordResetSuccessful />} /> ;
    createAppRoute = <Route path="/35lz6vm87q425vd9-create-app" element={<PrivateRoute component={<AcctAppCreatePage />} allowAccess={eligibilityCheck} />} /> ; 
    updateAppRoute = <Route path="/35lz6vm87q425vd9-update-app" element={<PrivateRoute component={<AcctAppUpdatePage />} allowAccess={eligibilityCheck} />} /> ; 
    portfolioRoute = <Route path="/35lz6vm87q425vd9-portfolio" element={<PrivateRoute component={<AcctPortfolioPage />} allowAccess={eligibilityCheck} />} /> ;
    previewRoute = <Route path="/35lz6vm87q425vd9-preview" element={<PrivateRoute component={<AcctAppPreviewPage />} allowAccess={eligibilityCheck} />} /> ;
    createAcctRoute = <Route path="/create-account" element={<AcctCreatePage />} /> ;
    tempDevContactRoute = <Route path="/developer-contact" element={<DeveloperContact />} /> ;
    acctDetailsRoute = <Route path="/35lz6vm87q425vd9-acct-details" element={<PrivateRoute component={<AcctDetailsPage />} allowAccess={eligibilityCheck} />} /> ;
    gameInfoRoute = <Route path="/game-info" element={<PrivateRoute component={<GameInfoPage />} allowAccess={eligibilityCheck} />} /> ;
    acctAppDetailsRoute = <Route path="/app-details" element={<PrivateRoute component={<AcctAppDetailsPage />} allowAccess={eligibilityCheck} />} /> ;
  } else {
    homeComponent = Homepage;
    tempHomeRoute = <Route path="/" element={<Homepage />} /> ;
    tempAdminRoute = <Route path="/35lz6vm87q425vd9-admin" element={<AdminPage />} /> ;
    tempGameRoute = <Route path="/35lz6vm87q425vd9-game" element={<MazeGame />} /> ;
    tempBallAnimationRoute = <Route path="/35lz6vm87q425vd9-ball-animation" element={<BallAnimation />} /> ;
  }

  return (
    <AuthProvider>
      <Router>
        <Routes>
        <Route path="/35lz6vm87q425vd9" element={<PrivateRoute element={React.createElement(homeComponent)} />} />

        /* Developer specific pages */
        {loginRoute}
        {forgotPassRoute}
        {devApprovalRoute}
        {passResetRoute}
        {createAppRoute}
        {updateAppRoute}
        {portfolioRoute}
        {previewRoute}
        {createAcctRoute}
        {acctDetailsRoute}
        {gameInfoRoute}
        {acctAppDetailsRoute}
        /* Public website specific pages */
        {tempHomeRoute}
        {tempAdminRoute}
        {tempGameRoute}
        {tempDevContactRoute}
        {tempBallAnimationRoute}
      </Routes>
    </Router>
    </AuthProvider>
  );
};

export default App;

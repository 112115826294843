import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import styles from './GameInfoPage.module.css';
import { devClient } from '../api/client';

const GameInfoPage = () => {
  const location = useLocation();
  const { game } = location.state || {};

  const [isEditing, setIsEditing] = useState(false);
  const [activeTab, setActiveTab] = useState(1);
  const [gameInfo, setGameInfo] = useState(game);

  const handleEditToggle = () => {
    setIsEditing(!isEditing);
  };

  const handleSave = async () => {
    try {
      await devClient.put(`/games/${gameInfo.game_id}`, gameInfo);
      setIsEditing(false);
    } catch (error) {
      console.error("Error saving game info:", error);
    }
  };

  const handleCancel = () => {
    setGameInfo(game);
    setIsEditing(false);
  };

  const handleTabChange = (tabNumber) => {
    setActiveTab(tabNumber);
  };

  if (!gameInfo) {
    return <div>No game data available</div>;
  }

  return (
    <div className={styles.uploadPage}>
      <div className={styles.tabs}>
        <div 
          className={`${styles.tab} ${activeTab === 1 ? styles.active : ''}`}
          onClick={() => handleTabChange(1)}
        >
          Game Details
        </div>
        <div 
          className={`${styles.tab} ${activeTab === 2 ? styles.active : ''}`}
          onClick={() => handleTabChange(2)}
        >
          Technical Details
        </div>
      </div>

      <form>
        {activeTab === 1 && (
          <div className={styles.tabContent}>
            <div className={styles.formContent}>
              <section className={styles.basicInfo}>
                <h2>Basic Information</h2>
                <div className={styles.formGroup}>
                  <label>Game Name *</label>
                  <input
                    type="text"
                    value={gameInfo.name}
                    onChange={(e) => setGameInfo({ ...gameInfo, name: e.target.value })}
                    disabled={!isEditing}
                  />
                </div>
                <div className={styles.formGroup}>
                  <label>Categories *</label>
                  <input
                    type="text"
                    value={gameInfo.categories.join(', ')}
                    disabled
                  />
                </div>
                <div className={styles.formGroup}>
                  <label>Short Description *</label>
                  <textarea
                    value={gameInfo.short_description}
                    onChange={(e) => setGameInfo({ ...gameInfo, short_description: e.target.value })}
                    disabled={!isEditing}
                  />
                </div>
                <div className={styles.formGroup}>
                  <label>Detailed Description *</label>
                  <textarea
                    value={gameInfo.detailed_description}
                    onChange={(e) => setGameInfo({ ...gameInfo, detailed_description: e.target.value })}
                    disabled={!isEditing}
                  />
                </div>
                <div className={styles.formGroup}>
                  <label>Support Email *</label>
                  <input
                    type="email"
                    value={gameInfo.support_email}
                    onChange={(e) => setGameInfo({ ...gameInfo, support_email: e.target.value })}
                    disabled={!isEditing}
                  />
                </div>
                <div className={styles.formGroup}>
                  <label>Age Rating *</label>
                  <input
                    type="text"
                    value={gameInfo.age_rating}
                    disabled
                  />
                </div>
                <div className={styles.formGroup}>
                  <label>Price *</label>
                  <select
                    value={gameInfo.price_type}
                    onChange={(e) => setGameInfo({ ...gameInfo, price_type: e.target.value })}
                    disabled={!isEditing}
                  >
                    <option value="free">Free</option>
                    <option value="paid">Paid</option>
                  </select>
                  {gameInfo.price_type === 'paid' && (
                    <input
                      type="number"
                      value={gameInfo.price_amount}
                      onChange={(e) => setGameInfo({ ...gameInfo, price_amount: e.target.value })}
                      placeholder="Enter price in USD"
                      min="0.99"
                      step="0.01"
                      disabled={!isEditing}
                    />
                  )}
                </div>
              </section>
            </div>
          </div>
        )}

        {activeTab === 2 && (
          <div className={styles.tabContent}>
            <div className={styles.formContent}>
              <section className={styles.technicalDetails}>
                <h2>Technical Details</h2>
                <div className={styles.formGroup}>
                  <label>Platform(s) *</label>
                  <input
                    type="text"
                    value={Object.keys(gameInfo.platforms).filter(platform => gameInfo.platforms[platform]).join(', ')}
                    disabled
                  />
                </div>
                <div className={styles.formGroup}>
                  <label>Country Restrictions *</label>
                  <input
                    type="text"
                    value={`${gameInfo.country_restrictions.type}: ${gameInfo.country_restrictions.countries.join(', ')}`}
                    disabled
                  />
                </div>
                <div className={styles.formGroup}>
                  <label className={styles.checkboxContainer}>
                    <input
                      type="checkbox"
                      checked={gameInfo.has_in_app_purchases}
                      onChange={(e) => setGameInfo({ ...gameInfo, has_in_app_purchases: e.target.checked })}
                      disabled={!isEditing}
                    />
                    <span className={styles.checkboxLabel}>Contains In-App Purchases</span>
                  </label>
                </div>
                <div className={styles.formGroup}>
                  <label className={styles.checkboxContainer}>
                    <input
                      type="checkbox"
                      checked={gameInfo.has_ads}
                      onChange={(e) => setGameInfo({ ...gameInfo, has_ads: e.target.checked })}
                      disabled={!isEditing}
                    />
                    <span className={styles.checkboxLabel}>Contains Advertisements</span>
                  </label>
                </div>
              </section>
            </div>
          </div>
        )}

        <div className={styles.buttonContainer}>
          {isEditing ? (
            <>
              <button type="button" className={styles.saveBtn} onClick={handleSave}>Save</button>
              <button type="button" className={styles.cancelBtn} onClick={handleCancel}>Cancel</button>
            </>
          ) : (
            <button type="button" className={styles.editBtn} onClick={handleEditToggle}>Edit</button>
          )}
        </div>
      </form>
    </div>
  );
};

export default GameInfoPage;

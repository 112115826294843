import React from 'react';
import AcctAppDetailsComponent from './AcctAppDetailsComponent';
import AcctHeaderBar from './AcctHeaderBar';
import FooterBar from '../common/FooterBar';

const AcctAppCreatePage = () => {
  const menuItems = [
    // Add menu items if needed
  ];

  return (
    <div>
      <AcctHeaderBar menuItems={menuItems} isSignedIn={true} />
      <AcctAppDetailsComponent />
      <FooterBar />
    </div>
  );
};

export default AcctAppCreatePage; 
import React, { useEffect, useState } from 'react';
import styles from './AcctPortfolioPage.module.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPuzzlePiece, faBars, faTimes, faGun, faChartLine, faCreditCard, faUser, faBullseye } from '@fortawesome/free-solid-svg-icons';
import AcctHeaderBar from './AcctHeaderBar';
import FooterBar from '../common/FooterBar';
import { devClient } from '../api/client';
import { useNavigate } from 'react-router-dom';

import ext_link_icn from '../../assets/icons/external-link.png'; 

const PortfolioPage = () => {
  const [isSidebarExpanded, setIsSidebarExpanded] = useState(true);
  const [selectedApp, setSelectedApp] = useState(null);
  const [apps, setApps] = useState([]);
  const [newStatus, setNewStatus] = useState(null);
  const [refreshMessage, setRefreshMessage] = useState('');
  const [games, setGames] = useState([]);
  const navigate = useNavigate();

  const handleChangeStatus = async (version) => {
    const confirmChange = window.confirm(`Are you sure you want to change the status to ${newStatus}?`);
    if (confirmChange) {
      try {
        const accessToken = await localStorage.getItem('accessToken');
        const endpoint = selectedApp.game_id ? `/v1/games/${selectedApp.game_id}/status/` : `/v1/agents/${selectedApp.agent_id}/status/`;
        await devClient.post(endpoint, { status: newStatus, version: version }, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${accessToken}`,
          },
        });
        alert('Status changed successfully');
        setRefreshMessage("Refresh the page to reflect correct status.");
      } catch (error) {
        console.error('Error changing status:', error);
        alert('Failed to change status');
      }
    }
  };

  useEffect(() => {
    const fetchAppsAndGames = async () => {
      const accessToken = await localStorage.getItem('accessToken');
      
      // Fetch apps
      const appsResponse = await devClient.get('/v1/agents/', {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${accessToken}`,
        },
      });
      setApps(appsResponse.data);

      // Fetch games
      const gamesResponse = await devClient.get('/v1/games', {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${accessToken}`,
        },
      });
      setGames(gamesResponse.data);

      console.log("Response received for portfolio: ", appsResponse.data);
      console.log("Response received for games: ", gamesResponse.data);
    }

    fetchAppsAndGames();
  }, []);

  const toggleSidebar = () => {
    setIsSidebarExpanded(!isSidebarExpanded);
  };

  const handleRowClick = (item) => {
    // Set the selected item (app or game) to open the side panel
    setSelectedApp(item);
    setNewStatus(item.status === 'LIVE' ? 'SUSPENDED' : 'LIVE');
  };

  const navigateToAppInfo = (app) => {
    navigate('/game-info', { state: { app } });
  };

  const closeAppSummary = () => {
    setSelectedApp(null);
    setNewStatus(null);
  };

  const createNewApp = () => {
    navigate('/35lz6vm87q425vd9-create-app');
  };

  const updateApp = (selectedApp) => {
    if (selectedApp.game_id) {
      // Navigate to app-details page for games
      navigate('/app-details', { state: { game: selectedApp } });
    } else {
      // Existing behavior for apps
      navigate('/35lz6vm87q425vd9-update-app', { state: { app_json: selectedApp } });
    }
  };

  const previewApp = (event, selectedApp) => {
    //event.preventDefault(); 
    navigate('/35lz6vm87q425vd9-preview', { state: { app_json: selectedApp } });
  };

  const handleAccountClick = () => {
    navigate('/35lz6vm87q425vd9-acct-details');
  };

  const menuItems = [
    { label: 'Dashboard', href: '/35lz6vm87q425vd9-portfolio' },
    { label: 'Documentation', href: '#docs' },
  ];

  return (
    <div className={styles.portfolioPage}>
      <AcctHeaderBar menuItems={menuItems} isSignedIn={true} />
      <div className={styles.mainContent}>
        <aside className={`${styles.sidebar} ${isSidebarExpanded ? styles.expanded : styles.collapsed}`}>
          <div className={styles.toggleBtn} onClick={toggleSidebar}>
            <FontAwesomeIcon icon={faBars} />
          </div>
          {isSidebarExpanded && (
            <nav>
              <h2>Manager</h2>
              <ul>
                <li className={styles.active}>
                  <FontAwesomeIcon icon={faPuzzlePiece} />
                  Portfolio
                </li>
                <li>
                  <FontAwesomeIcon icon={faChartLine} />
                  Analytics
                </li>
                <li>
                  <FontAwesomeIcon icon={faCreditCard} />
                  Payments
                </li>
                <li>
                  <FontAwesomeIcon icon={faBullseye} />
                  Ads & Campaigns
                </li>

                <li onClick={handleAccountClick} className={styles.menuItem}>
                  <FontAwesomeIcon icon={faUser} />
                  Account
                </li>
              </ul>
              <button className={styles.newApp} onClick={createNewApp}>New App</button>
            </nav>
          )}
        </aside>
        <main className={styles.content}>
          <h1>Manage your apps and games</h1>
          <table>
            <thead>
              <tr>
                <th>App/Game name</th>
                <th>Version</th>
                <th>Status</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {apps.map((app, index) => (
                <tr key={`app-${index}`} onClick={() => handleRowClick(app)}>
                  <td>
                    <FontAwesomeIcon icon={faPuzzlePiece} />
                    <a href="#">{app.name}</a>
                  </td>
                  <td>{app.version}</td>
                  <td>
                    <span className={`${styles.status} ${styles[app.status.toLowerCase()]}`}>{app.status}</span>
                  </td>
                  <td><a href="#">{app.pending_actions_count}</a></td>
                </tr>
              ))}
              {games.map((game, index) => (
                <tr key={`game-${index}`} className={styles.gameRow} onClick={() => handleRowClick(game)}>
                  <td>
                    <FontAwesomeIcon icon={faPuzzlePiece} />
                    <a href="#">{game.name}</a>
                  </td>
                  <td>{game.version}</td>
                  <td>
                    <span className={`${styles.status} ${styles[game.status.toLowerCase()]}`}>{game.status}</span>
                  </td>
                  <td><a href="#">{game.pending_actions_count}</a></td>
                </tr>
              ))}
            </tbody>
          </table>
        </main>
        <aside className={`${styles.appSummary} ${selectedApp ? styles.expanded : styles.collapsed}`}>
          {selectedApp && (
            <div className={styles.summaryContent}>
              <div className={styles.summaryHeader}>
                <h2>{selectedApp.name}
                  <a href="#" onClick={(event) => previewApp(event, selectedApp)}>
                    <img src={ext_link_icn} alt="External Link" className={styles.externalLinkIcon} />
                  </a>
                </h2>
                <button className={styles.closeBtn} onClick={closeAppSummary}>
                  <FontAwesomeIcon icon={faTimes} />
                </button>
              </div>

              {selectedApp.game_id ? (
                // Render fields specific to games
                <>
                  <p><strong>Game Id:</strong> {selectedApp.game_id}</p>
                  <p><strong>Version:</strong> {selectedApp.version}</p>
                  <p><strong>Status:</strong> {selectedApp.status}</p>
                </>
              ) : (
                // Render fields specific to apps
                <>
                  <p><strong>Agent Id:</strong> {selectedApp.agent_id}</p>
                  <p><strong>Version:</strong> {selectedApp.version}</p>
                  <p><strong>Status:</strong> {selectedApp.status}</p>
                </>
              )}

              {(selectedApp.status === 'LIVE' || selectedApp.status === 'SUSPENDED') && (
                <div className={styles.statusChangeRow}>
                  <select value={newStatus} onChange={(e) => setNewStatus(e.target.value)} className={styles.statusDropdown}>
                    {selectedApp.status === 'LIVE' && (
                      <>
                        <option value="SUSPENDED">SUSPENDED</option>
                        <option value="EXITED">EXITED</option>
                      </>
                    )}
                    {selectedApp.status === 'SUSPENDED' && (
                      <>
                        <option value="LIVE">LIVE</option>
                        <option value="EXITED">EXITED</option>
                      </>
                    )}
                  </select>
                  <button className={styles.confirmBtn} onClick={() => handleChangeStatus(selectedApp.version)}>Change Status</button>
                </div>
              )}

              <div className={styles.refreshMessage}>{refreshMessage}</div>
              <p><strong>Summary:</strong> {selectedApp.summary}</p>
              <p><strong>Description:</strong></p>
              <>{selectedApp.description}</>
              <p><strong>Actions:</strong> {selectedApp.pending_actions_count}</p>
              <p><strong>Actions List:</strong> </p>
              {selectedApp.pending_actions && selectedApp.pending_actions.length > 0 ? (
                <div className={styles.pendingActionList}>
                  {selectedApp.pending_actions.map((action, index) => (
                    <p key={index}> <FontAwesomeIcon icon={faGun} /> {action.description}</p>
                  ))}
                </div>
              ) : (
                <p className={styles.noPendingActions}>No pending actions</p>
              )}

              <button className={styles.newApp} onClick={() => updateApp(selectedApp)}>Update App</button>
            </div>
          )}
        </aside>
      </div>
      <FooterBar/>
    </div>
  );
};

export default PortfolioPage;
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styles from './AcctForgotPassPage.module.css';
import HeaderBar from './AcctHeaderBar';

const ForgotPasswordPage = () => {
  const [email, setEmail] = useState('');
  const [code, setCode] = useState('');
  const [password, setPassword] = useState('');
  const [rePassword, setRePassword] = useState('');
  const [emailSent, setEmailSent] = useState(false);
  const [codeVerified, setCodeVerified] = useState(false);
  const navigate = useNavigate();

  const handleSendCode = async () => {
    // Simulating API call
    setEmailSent(true);
  };

  const handleVerifyCode = async () => {
    // Simulating API call
    setCodeVerified(true);
  };

  const handleResetPassword = async () => {
    // Simulating API call
    if (password === rePassword) {
      navigate('/password-reset-successful');
    }
  };

  const menuItems = [
    { type: 'link', label: 'Docs', href: '#' },
    { type: 'link', label: 'API Reference', href: '#' },
    { type: 'link', label: 'Learn', href: '#' },
    { type: 'link', label: 'Community', href: '#' },
  ];

  return (
    <div className={styles.forgotPasswordPage}>
      <HeaderBar menuItems={menuItems} />
      <div className={styles.pageHeading}>
        <h1>Recover Developer Account</h1>
        <p>We will help you to recover your account. Follow the steps provided below to reset your account password.</p>
      </div>

      <div className={styles.contentWrapper}>

        <div className={styles.formGroup}>
          <div className={styles.formRow}>
            <label htmlFor="email">Email:</label>
            <div className={styles.inputGroup}>
              <input
                type="email"
                id="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                className={styles.input}
                disabled={emailSent}
              />
              <button onClick={handleSendCode} className={styles.actionButton} disabled={emailSent}>Send</button>
            </div>
          </div>
          <div className={styles.messageContainer}>
            {emailSent && <p className={styles.successMessage}>verification code is sent to your email.</p>}
            <p className={styles.instruction}>Please check your email inbox to find out the reset code sent.</p>
          </div>
        </div>

        <div className={styles.formGroup}>
          <div className={styles.formRow}>
            <label htmlFor="code">Reset Code:</label>
            <div className={styles.inputGroup}>
              <input
                type="text"
                id="code"
                value={code}
                onChange={(e) => setCode(e.target.value)}
                className={styles.input}
                disabled={!emailSent || codeVerified}
              />
              <button onClick={handleVerifyCode} className={styles.actionButton} disabled={!emailSent || codeVerified}>Verify</button>
            </div>
          </div>
          <div className={styles.messageContainer}>
            {codeVerified && <p className={styles.successMessage}>Verification successful.</p>}
            <p className={styles.instruction}>Once verification is successful, please proceed with resetting password.</p>
          </div>
        </div>

        <div className={styles.formGroup}>
          <div className={styles.formRow}>
            <label htmlFor="password">Password:</label>
            <div className={styles.inputGroup}>
              <input
                type="password"
                id="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                className={styles.input}
                disabled={!codeVerified}
              />
            </div>
          </div>
        </div>

        <div className={styles.formGroup}>
          <div className={styles.formRow}>
            <label htmlFor="rePassword">Re-enter Password:</label>
            <div className={styles.inputGroup}>
              <input
                type="password"
                id="rePassword"
                value={rePassword}
                onChange={(e) => setRePassword(e.target.value)}
                className={styles.input}
                disabled={!codeVerified}
              />
            </div>
          </div>
        </div>

        <div className={styles.formGroup}>
          <div className={styles.formRow}>
            <label htmlFor="dummy"></label>
            <div className={styles.resetButtonContainer}>
            <button onClick={handleResetPassword} className={styles.resetButton} disabled={!codeVerified}>Reset Password</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ForgotPasswordPage;
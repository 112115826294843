import React from 'react';
import { Link } from 'react-router-dom';
import styles from './AcctPassResetSuccess.module.css';
import AcctHeaderBar from './AcctHeaderBar';

const PasswordResetSuccessful = () => {
  const menuItems = [
    { type: 'link', label: 'Docs', href: '#' },
    { type: 'link', label: 'API Reference', href: '#' },
    { type: 'link', label: 'Learn', href: '#' },
    { type: 'link', label: 'Community', href: '#' },
  ];

  return (
    <div className={styles.resetSuccessfulPage}>
      <AcctHeaderBar menuItems={menuItems} />
      <div className={styles.contentWrapper}>
        <h1>Password Reset Successful</h1>
        <p>Your password has been successfully reset.</p>
        <p>Please go back to the login page and sign in with your new password.</p>
        <Link to="/login" className={styles.loginLink}>Go to Login Page</Link>
      </div>
    </div>
  );
};

export default PasswordResetSuccessful;